import axios from 'axios';

const TYPEFORM_URL = import.meta.env.VITE_TYPEFORM_URL;
const API_BASE_URL = '';  // Remove /api since it's handled by Netlify redirects

interface CallResponse {
  success: boolean;
  message: string;
  data?: any;
}

// Test function to verify endpoints
export async function testEndpoints(): Promise<{
  success: boolean;
  testEndpoint: any;
  callEndpoint: any;
}> {
  console.log('=== Testing Both Endpoints ===');
  
  try {
    // Test the test endpoint
    console.log('Testing test-endpoint...');
    const testResponse = await axios.post(`${API_BASE_URL}/.netlify/functions/test-endpoint`, {
      test: true,
      timestamp: new Date().toISOString()
    });
    console.log('Test endpoint response:', testResponse.data);

    // Test the initiate-call endpoint
    console.log('Testing initiate-call endpoint...');
    const callResponse = await axios.post(`${API_BASE_URL}/.netlify/functions/initiate-call`, {
      phoneNumber: '555-555-5555',
      firstName: 'Test',
      conversationType: 'demo'
    });
    console.log('Initiate call response:', callResponse.data);

    return {
      success: true,
      testEndpoint: testResponse.data,
      callEndpoint: callResponse.data
    };
  } catch (error) {
    console.error('=== Endpoint Test Error ===');
    if (axios.isAxiosError(error)) {
      console.error('Axios Error Details:', {
        status: error.response?.status,
        statusText: error.response?.statusText,
        headers: error.response?.headers,
        data: error.response?.data,
        config: {
          url: error.config?.url,
          method: error.config?.method,
          headers: error.config?.headers
        }
      });
    }
    throw error;
  }
}

export async function initiateCall(phoneNumber: string, firstName: string = 'there'): Promise<any> {
  const response = await fetch('/.netlify/functions/initiate-call', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phoneNumber,
      firstName
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to initiate call');
  }

  return response.json();
}

export async function sendSMSWithTypeform(phoneNumber: string): Promise<CallResponse> {
  try {
    console.log('Sending SMS to:', phoneNumber);
    const response = await fetch(`${API_BASE_URL}/send-sms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber: phoneNumber.replace(/\D/g, ''), // Strip non-digits
        message: `Thanks for your interest! Please fill out this quick form and we'll be in touch: ${TYPEFORM_URL}`
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ error: 'Unknown error' }));
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error sending SMS:', error);
    throw error;
  }
} 