import React from 'react';
import { cn } from '../lib/utils';

export type CallState = 'idle' | 'initiating' | 'calling' | 'connected' | 'in-progress' | 'completed' | 'error';

interface CallStatusProps {
  state: CallState;
  className?: string;
}

const stateColors = {
  idle: 'bg-gray-100 text-gray-700',
  initiating: 'bg-blue-100 text-blue-700',
  calling: 'bg-yellow-100 text-yellow-700',
  connected: 'bg-green-100 text-green-700',
  'in-progress': 'bg-green-100 text-green-700',
  completed: 'bg-green-100 text-green-700',
  error: 'bg-red-100 text-red-700'
};

export const CallStatus: React.FC<CallStatusProps> = ({ state, className }) => {
  const getStatusContent = () => {
    switch (state) {
      case 'idle':
        return null;
      case 'initiating':
        return (
          <h3 className="text-xl font-semibold text-gray-900">
            Initiating call...
          </h3>
        );
      case 'calling':
        return (
          <h3 className="text-xl font-semibold text-gray-900">
            Calling you now
          </h3>
        );
      case 'connected':
        return (
          <h3 className="text-xl font-semibold text-gray-900">
            Call connected!
          </h3>
        );
      case 'in-progress':
        return (
          <h3 className="text-xl font-semibold text-gray-900">
            Call in progress
          </h3>
        );
      case 'completed':
        return (
          <h3 className="text-xl font-semibold text-gray-900">
            Call completed successfully
          </h3>
        );
      case 'error':
        return (
          <h3 className="text-xl font-semibold text-gray-900">
            Call error
          </h3>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={cn(
        'px-4 py-2 rounded-lg text-center font-medium transition-colors',
        stateColors[state],
        className
      )}
    >
      <span className="inline-flex items-center gap-2">
        <StatusDot state={state} />
        {getStatusContent()}
      </span>
    </div>
  );
};

function StatusDot({ state }: { state: CallState }) {
  const dotColors = {
    idle: 'bg-gray-500',
    initiating: 'bg-blue-500',
    calling: 'bg-yellow-500',
    connected: 'bg-green-500',
    'in-progress': 'bg-green-500',
    completed: 'bg-green-500',
    error: 'bg-red-500'
  };

  return (
    <span
      className={cn(
        'w-2 h-2 rounded-full animate-pulse',
        dotColors[state]
      )}
    />
  );
} 