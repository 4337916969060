import { Bot } from 'lucide-react';
import { Link } from 'react-router-dom';

const Terms = (): JSX.Element => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 flex flex-col">
      <div className="flex-grow">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="flex items-center justify-center mb-10 space-x-3">
            <Link to="/" className="flex items-center space-x-3 cursor-pointer">
              <Bot className="h-12 w-12 text-indigo-600" />
              <span className="text-3xl font-bold text-indigo-600">callsetter</span>
            </Link>
          </div>
          
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-6">Terms of Service</h1>
            
            <div className="prose max-w-none">
              <p className="mb-4">Last Updated: {new Date().toLocaleDateString()}</p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">1. Acceptance of Terms</h2>
              <p>
                By accessing or using Callsetter services, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions, you may not access or use our services.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">2. Description of Service</h2>
              <p>
                Callsetter provides AI-powered appointment setting and call services for real estate professionals. Our service allows automated communication with potential clients through voice calls.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">3. User Accounts</h2>
              <p>
                You may need to create an account to use certain features of our service. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">4. Privacy Policy</h2>
              <p>
                Your use of Callsetter is also governed by our Privacy Policy, which is incorporated by reference into these Terms of Service.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">5. Termination</h2>
              <p>
                We reserve the right to terminate or suspend your account and access to our services at our sole discretion, without notice, for conduct that we believe violates these Terms of Service or is harmful to other users of the service, us, or third parties, or for any other reason.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">6. Changes to Terms</h2>
              <p>
                We reserve the right to modify these terms at any time. We will provide notice of significant changes to these terms by posting a notice on our homepage or sending you an email.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">7. Contact</h2>
              <p>
                If you have any questions about these Terms, please contact us at support@callsetter.com.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex justify-center space-x-6">
            <a href="/terms" className="text-sm text-gray-600 hover:text-indigo-600">Terms of Service</a>
            <a href="/privacy" className="text-sm text-gray-600 hover:text-indigo-600">Privacy Policy</a>
            <a href="/cookies" className="text-sm text-gray-600 hover:text-indigo-600">Cookie Policy</a>
          </div>
          <div className="mt-4">
            <p className="text-sm text-gray-500 text-center">
              © {new Date().getFullYear()} callsetter. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Terms; 