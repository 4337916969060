import { Bot } from 'lucide-react';
import { Link } from 'react-router-dom';

const Cookies = (): JSX.Element => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 flex flex-col">
      <div className="flex-grow">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="flex items-center justify-center mb-10 space-x-3">
            <Link to="/" className="flex items-center space-x-3 cursor-pointer">
              <Bot className="h-12 w-12 text-indigo-600" />
              <span className="text-3xl font-bold text-indigo-600">callsetter</span>
            </Link>
          </div>
          
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-6">Cookie Policy</h1>
            
            <div className="prose max-w-none">
              <p className="mb-4">Last Updated: {new Date().toLocaleDateString()}</p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">1. What Are Cookies</h2>
              <p>
                Cookies are small text files that are placed on your device when you visit a website. They are widely used to make websites work more efficiently and provide information to the website owners.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">2. How We Use Cookies</h2>
              <p>
                We use cookies for the following purposes:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li><strong>Essential Cookies:</strong> These cookies are necessary for the website to function properly and cannot be switched off in our systems.</li>
                <li><strong>Performance Cookies:</strong> These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site.</li>
                <li><strong>Functionality Cookies:</strong> These cookies enable the website to provide enhanced functionality and personalization.</li>
                <li><strong>Targeting Cookies:</strong> These cookies may be set through our site by our advertising partners to build a profile of your interests and show you relevant advertisements on other sites.</li>
              </ul>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">3. Types of Cookies We Use</h2>
              <p>
                <strong>Session Cookies:</strong> These cookies are temporary and are erased when you close your browser.
              </p>
              <p>
                <strong>Persistent Cookies:</strong> These cookies remain on your device for a defined period or until you delete them.
              </p>
              <p>
                <strong>First-Party Cookies:</strong> These cookies are set by the website you are visiting.
              </p>
              <p>
                <strong>Third-Party Cookies:</strong> These cookies are set by a different organization than the owner of the website you are visiting.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">4. How to Manage Cookies</h2>
              <p>
                Most web browsers allow you to manage your cookie preferences. You can set your browser to refuse cookies, or to alert you when cookies are being sent. The following links provide information on how to modify your browser's settings to block or delete cookies:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li><a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop" className="text-indigo-600 hover:text-indigo-800">Mozilla Firefox</a></li>
                <li><a href="https://support.google.com/chrome/answer/95647" className="text-indigo-600 hover:text-indigo-800">Google Chrome</a></li>
                <li><a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" className="text-indigo-600 hover:text-indigo-800">Microsoft Edge</a></li>
                <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" className="text-indigo-600 hover:text-indigo-800">Safari</a></li>
              </ul>
              <p>
                Please note that restricting cookies may impact the functionality of our website.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">5. Changes to this Cookie Policy</h2>
              <p>
                We may update this Cookie Policy from time to time to reflect changes in technology, regulation, or our business practices. Any changes will become effective when we post the revised policy on this page.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">6. Contact Us</h2>
              <p>
                If you have any questions about our use of cookies, please contact us at cookies@callsetter.com.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex justify-center space-x-6">
            <a href="/terms" className="text-sm text-gray-600 hover:text-indigo-600">Terms of Service</a>
            <a href="/privacy" className="text-sm text-gray-600 hover:text-indigo-600">Privacy Policy</a>
            <a href="/cookies" className="text-sm text-gray-600 hover:text-indigo-600">Cookie Policy</a>
          </div>
          <div className="mt-4">
            <p className="text-sm text-gray-500 text-center">
              © {new Date().getFullYear()} callsetter. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Cookies; 