import { Bot } from 'lucide-react';
import { Link } from 'react-router-dom';

const Privacy = (): JSX.Element => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 flex flex-col">
      <div className="flex-grow">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="flex items-center justify-center mb-10 space-x-3">
            <Link to="/" className="flex items-center space-x-3 cursor-pointer">
              <Bot className="h-12 w-12 text-indigo-600" />
              <span className="text-3xl font-bold text-indigo-600">callsetter</span>
            </Link>
          </div>
          
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-6">Privacy Policy</h1>
            
            <div className="prose max-w-none">
              <p className="mb-4">Last Updated: {new Date().toLocaleDateString()}</p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">1. Introduction</h2>
              <p>
                This Privacy Policy explains how Callsetter collects, uses, and shares information about you when you use our services. By using our services, you agree to the collection and use of information in accordance with this policy.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">2. Information We Collect</h2>
              <p>
                We collect the following types of information:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Information you provide to us directly, such as your name, email address, phone number, and any other information you provide when using our services.</li>
                <li>Information we collect automatically when you use our services, such as usage data, IP address, device information, and cookies.</li>
                <li>Call recordings and transcripts when you interact with our AI assistant.</li>
              </ul>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">3. How We Use Your Information</h2>
              <p>
                We use the information we collect to:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Provide, maintain, and improve our services.</li>
                <li>Process transactions and send related information.</li>
                <li>Send administrative messages, updates, and promotional materials.</li>
                <li>Monitor and analyze trends, usage, and activities in connection with our services.</li>
                <li>Personalize and improve your experience with our services.</li>
                <li>Train and improve our AI systems for better customer service.</li>
              </ul>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">4. Sharing of Information</h2>
              <p>
                We may share information about you in the following circumstances:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf.</li>
                <li>In response to a request for information if we believe disclosure is in accordance with applicable law.</li>
                <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of Callsetter or others.</li>
                <li>In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>
                <li>With your consent or at your direction.</li>
              </ul>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">5. Your Rights</h2>
              <p>
                Depending on your location, you may have certain rights regarding your personal information, such as the right to access, correct, delete, or restrict processing of your personal data.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">6. Changes to this Privacy Policy</h2>
              <p>
                We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice.
              </p>
              
              <h2 className="text-xl font-semibold mt-6 mb-3">7. Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy, please contact us at privacy@callsetter.com.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex justify-center space-x-6">
            <a href="/terms" className="text-sm text-gray-600 hover:text-indigo-600">Terms of Service</a>
            <a href="/privacy" className="text-sm text-gray-600 hover:text-indigo-600">Privacy Policy</a>
            <a href="/cookies" className="text-sm text-gray-600 hover:text-indigo-600">Cookie Policy</a>
          </div>
          <div className="mt-4">
            <p className="text-sm text-gray-500 text-center">
              © {new Date().getFullYear()} callsetter. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Privacy; 