import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { CallStatus, type CallState } from './components/CallStatus';
import { initiateCall } from './lib/api';

export default function App() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [callState, setCallState] = useState<CallState>('idle');
  const [error, setError] = useState<string | null>(null);

  const validatePhoneNumber = (number: string) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneRegex.test(number);
  };

  const formatPhoneNumber = (value: string) => {
    const numbers = value.replace(/\D/g, '');
    if (numbers.length === 0) return '';
    if (numbers.length <= 3) return numbers;
    if (numbers.length <= 6) return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
    return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    setPhoneNumber(formatted);
    setIsValid(validatePhoneNumber(formatted));
    setShowError(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValid || !firstName.trim()) {
      setShowError(true);
      return;
    }
    try {
      setError(null);
      setCallState('initiating');
      console.log('Initiating call to:', phoneNumber);
      
      const callResponse = await initiateCall(phoneNumber, firstName);
      console.log('Call initiated successfully:', callResponse);
      setCallState('calling');
      setIsSubmitted(true);
      
      // Wait for call to connect - this is a UI simulation
      setTimeout(() => {
        setCallState('connected');
        console.log('Call connected');
        
        // In production, we'd use a webhook or socket connection to get the real call status from VAPI
        // For now, show the questionnaire after connection so the user can proceed while on the call
        // The actual call completion and data will be handled by the webhook in the backend
        setTimeout(() => {
          setCallState('in-progress'); // Changed to 'in-progress' instead of 'completed'
          console.log('Call in progress, showing questionnaire');
        }, 15000); // Reduced from 30s to 15s
      }, 3000);
      
    } catch (err) {
      setCallState('error');
      const errorMessage = err instanceof Error ? err.message : 'An error occurred initiating the call';
      setError(errorMessage);
      console.error('Call error:', err);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 flex flex-col">
      <div className="flex-grow">
        <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="max-w-4xl mx-auto text-center mb-16">
            <div className="flex items-center justify-center mb-6">
              <a href="/" className="cursor-pointer">
                <span className="text-3xl font-bold text-indigo-600">callsetter</span>
              </a>
            </div>
            <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl mb-4 tracking-tight">
              AI-Powered Appointment Setter
              <br />
              <span className="text-3xl sm:text-4xl md:text-5xl font-bold mt-2 inline-block">for Realtors</span>
            </h1>
          </div>

          <div className="max-w-xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden">
            {!isSubmitted ? (
              <div className="p-8">
                <div className="space-y-8">
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                      <div className="space-y-4">
                        <div>
                          <input
                            type="text"
                            id="firstName"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="block w-full px-4 py-4 text-xl rounded-lg border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="First Name"
                          />
                        </div>

                        <div className="relative">
                          <input
                            type="tel"
                            id="phone"
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            className={`block w-full px-4 py-4 text-xl rounded-lg border ${
                              showError ? 'border-red-300' : 'border-gray-300'
                            } focus:ring-indigo-500 focus:border-indigo-500`}
                            placeholder="(555) 555-5555"
                          />
                          {showError && (
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                              <X className="h-5 w-5 text-red-500" />
                            </div>
                          )}
                        </div>
                        {showError && (
                          <p className="mt-2 text-sm text-red-600">
                            Please enter your name and a valid phone number
                          </p>
                        )}
                      </div>
                    </div>
                    
                    <button
                      type="submit"
                      className={`w-full flex justify-center py-4 px-6 border border-transparent rounded-lg shadow-sm text-xl font-semibold text-white ${
                        isValid && firstName.trim().length > 0
                          ? 'bg-indigo-600 hover:bg-indigo-700'
                          : 'bg-indigo-400 cursor-not-allowed'
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      disabled={!isValid || firstName.trim().length === 0}
                    >
                      Give It a Try
                    </button>
                  </form>

                  <div className="text-sm text-gray-500 text-center">
                    You'll receive a call within the next minute or two!
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-8 text-center">
                <CallStatus state={callState} />
                {error && (
                  <p className="mt-4 text-red-600">
                    {error}
                  </p>
                )}
                {(callState === 'completed' || callState === 'in-progress') && (
                  <div className="mt-8">
                    <a 
                      href="https://form.typeform.com/to/dqy0HgMM" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="inline-block bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-4 px-8 rounded-lg text-xl transition-colors duration-200"
                    >
                      Start Questionnaire
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex justify-center space-x-6">
            <Link to="/terms" className="text-sm text-gray-600 hover:text-indigo-600">Terms of Service</Link>
            <Link to="/privacy" className="text-sm text-gray-600 hover:text-indigo-600">Privacy Policy</Link>
            <Link to="/cookies" className="text-sm text-gray-600 hover:text-indigo-600">Cookie Policy</Link>
          </div>
          <div className="mt-4">
            <p className="text-sm text-gray-500 text-center">
              © {new Date().getFullYear()} callsetter. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}